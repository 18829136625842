import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['btn'];

  connect() {
    this.btnTarget.addEventListener("click", this.close.bind(this));
  }

  close(e) {
    const flashContainer = e.currentTarget.closest(".flash-messages");
    e.currentTarget.closest(".flash").remove();
    flashContainer && !flashContainer.querySelector(".flash") && flashContainer.remove();
  }
};