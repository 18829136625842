import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['autoComplete', 'value', 'input', 'content'];

  connect() {

    this.init();
    this.autoCompleteTarget.addEventListener('auto-complete-change', e => {
      if (this.inputTarget.value) {
        const item = JSON.parse(this.inputTarget.value);
        this.valueTarget.value = item.id;
        this.contentTarget.innerHTML = item.content;
        this.showContent();
      }
    });
  }

  init() {
    if (this.valueTarget.value) {
      this.showContent();
    }
  }

  disconnect() {
  }

  showContent() {
    this.inputTarget.value = '';
    this.inputTarget.style = 'display: none';
    this.contentTarget.style = 'display: block';
  }

  reset() {
    this.contentTarget.innerHTML = '';
    this.contentTarget.style = 'display: none';
    this.inputTarget.style = 'display: block';
    this.valueTarget.value = '';
  }
};