import { Controller } from 'stimulus';
import axios from 'axios';

axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';

class Popover {
  constructor(props) {
    this.el = document.querySelector('.js-hovercard-content');
    this.contentEl = this.el.children[0];
    this.hide = this.hide.bind(this);
    this.onEnter = this.onEnter.bind(this);
  }

  show(target, html) {
    clearTimeout(this.timeout);
    this.contentEl.innerHTML = '';
    this.el.style.display = 'block';
    // https://stackoverflow.com/questions/9284117/inserting-arbitrary-html-into-a-documentfragment
    this.contentEl.appendChild(document.createRange().createContextualFragment(html));
    const rect = target.getBoundingClientRect();
    const contentRect = this.contentEl.getBoundingClientRect();
    this.el.style.left = (rect.left + rect.width/2) - (contentRect.width / 2) + window.pageXOffset + 'px';
    this.el.style.top = (rect.top - contentRect.height) - 12 + window.pageYOffset + 'px';

    target.addEventListener('mouseleave', this.hide, { once: true });
  }

  hide() {
    this.timeout = setTimeout(() => {
      this.el.style.display = 'none';
      this.el.removeEventListener('mouseenter', this.onEnter);
    }, 200);
    this.el.addEventListener('mouseenter', this.onEnter, { once: true });
  }

  onEnter() {
    clearTimeout(this.timeout);
    this.el.addEventListener('mouseleave', () => {
      this.el.style.display = 'none';
    }, { once: true });
  }
}

const popover = new Popover();

export default class extends Controller {
  connect() {
    this.url = this.data.get('url');
    this.element.addEventListener('mouseenter', this.show.bind(this));
  }

  show() {
    if (this.partial) {
      popover.show(this.element, this.partial);
    } else {
      axios.get(this.url).then(res => {
        this.partial = res.data;
        popover.show(this.element, this.partial);
      });
    }
  }
};