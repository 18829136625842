import consumer from "./consumer"

consumer.subscriptions.create("OnlineChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    const el = document.querySelector(`[data-id='${data.id}']`);
    if (el) {
      if (data.online) {
        el.classList.add('member-item--online');
      } else {
        el.classList.remove('member-item--online');
      }
    }
  }
});