import { Controller } from 'stimulus';
import Tribute from "tributejs";
import axios from 'axios';
import Trix from 'trix';

export default class extends Controller {
  static targets = ['field'];

  connect() {
    this.editor = this.fieldTarget.editor;
    this.fieldTarget.addEventListener('trix-change', this.warnBeforeLeave.bind(this), { once: true });
    this.fieldTarget.addEventListener('trix-file-accept', this.validateFile.bind(this));
    this.initializeTribute();
  }

  warnBeforeLeave() {
    const callback = e => {
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', callback);
    this.fieldTarget.closest('form').addEventListener('submit', e => {
      window.removeEventListener('beforeunload', callback);
    });
  }

  disconnect() {
    this.mentionTribute.detach(this.fieldTarget);
    this.ticketTribute.detach(this.fieldTarget);
  }

  initializeTribute() {
    this.mentionTribute = new Tribute({
      trigger: '@',
      allowSpaces: true,
      lookup: 'name',
      values: this.fetchUsers.bind(this),
    });
    this.mentionTribute.attach(this.fieldTarget);
    this.mentionTribute.range.pasteHtml = this.pasteHTML.bind(this);

    this.ticketTribute = new Tribute({
      trigger: '#',
      allowSpaces: true,
      lookup: ticket => `#${ticket.sequential_id} ${ticket.subject}`,
      values: this.fetchTickets.bind(this),
    });
    this.ticketTribute.attach(this.fieldTarget);
    this.ticketTribute.range.pasteHtml = this.pasteHTML.bind(this);

    this.fieldTarget.addEventListener('tribute-replaced', this.replaced);
  }

  replaced({ detail: { item }}) {
    if (item) {
      const mention = item.original;
      const attachment = new Trix.Attachment({
        sgid: mention.sgid,
        content: mention.content,
      });
      this.editor.insertAttachment(attachment);
      this.editor.insertString(' ');
    }
  }

  fetchUsers(text, callback) {
    axios.get(this.data.get('mentionsUrl')).then(res => callback(res.data));
  }

  fetchTickets(text, callback) {
    axios.get(this.data.get('ticketsUrl')).then(res => callback(res.data));
  }

  pasteHTML(html, startPos, endPos) {
    const position = this.editor.getPosition();
    this.editor.setSelectedRange([position - endPos + startPos, position + 1]);
    this.editor.deleteInDirection('backward');
  }

  validateFile(e) {
    const MAX_FILE_SIZE = 1024 * 1024 * 3;
    if (e.file.size > MAX_FILE_SIZE) {
      e.preventDefault();
      alert("僅能上傳3MB以內的檔案");
    }
  }
};