import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form'];

  connect() {
    this.formTarget.addEventListener("ajax:success", this.success.bind(this));
  }

  success(data, status, xhr) {
    this.formTarget.reset();
  }
};