import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['template', 'checkpoints'];

  add(e) {
    e.preventDefault();
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime());
    this.checkpointsTarget.insertAdjacentHTML('beforeend', content)
    e.target.blur();
  }

  destroyCheckpoint(e) {
    e.preventDefault();
    const wrapper = e.target.closest('.checkpoint-fields');
    if (JSON.parse(wrapper.dataset.newRecord)) {
      wrapper.remove();
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1;
      wrapper.style.display = 'none';
    }
  }
};