import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['btn'];

  connect() {
    this.btnTarget.addEventListener("click", this.close.bind(this));
  }

  close(e) {
    this.btnTarget.closest("details").open = false;
  }
};