import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['preview', 'input'];

  connect() {
    this.inputTarget.addEventListener("input", this.update.bind(this));
    this.update();
  }

  update(e) {
    this.previewTarget.innerHTML = `專案網址：https://ticket.bincode.tw/<strong>${this.inputTarget.value}</strong>`;
  }
};